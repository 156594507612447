import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView"),
  },
  {
    path: "/complete/telegram",
    name: "telegram-compete",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TelegramComplete"),
  },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MainView"),
    children: [
      {
        path: "/",
        name: "Websites",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/Websites/WebsitesView"
          ),
      },
      {
        path: "/websites/:id",
        name: "website",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/WebsitePage/WebsitePage"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (!user && to.path !== "/login") {
    next("/login");
    return;
  }

  if (to.path === "/login" && user) {
    next("/");
    return;
  }

  next();
});

export default router;
